/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@layer components {
    /* .active {
      @apply bg-accent text-white rounded-md px-3 py-2 text-sm font-medium;
    }; */
    .tw-soft-icon-button {
      @apply inline-flex items-center gap-x-1.5 rounded-md disabled:bg-gray-300 px-2.5 py-1.5 text-sm font-semibold text-accent shadow-sm hover:bg-lime-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent
    };
    .tw-button-accent {
     @apply inline-flex justify-center gap-x-1.5 rounded-md bg-accent disabled:bg-gray-300 px-3 py-2 text-sm font-semibold text-white  shadow-sm hover:bg-lime-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent
    };
    .tw-button-warn {
      @apply inline-flex justify-center gap-x-1.5 rounded-md bg-warn disabled:bg-gray-300 px-3 py-2 text-sm font-semibold text-white  shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-warn
     };
     /* .tw-button {
      @apply inline-flex items-center gap-x-1.5 rounded-md  disabled:bg-gray-300 px-3 py-2 text-sm font-semibold shadow-sm text-gray-900 hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
     }; */
     .tw-button {
      @apply inline-flex justify-center gap-x-1.5 rounded-md disabled:bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-600  shadow-sm hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent
     };
      
    .tw-form-label {
      @apply block text-sm font-medium leading-6 text-gray-900
    };
    .tw-form-input {
      @apply block w-full rounded-md border-0 py-1.5 text-gray-900 disabled:bg-gray-300 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-accent-600 sm:text-sm sm:leading-6
    };
    .tw-form-hint {
      @apply ml-2 flex flex-row justify-start items-center text-xs font-light leading-6 text-gray-600
    };
    .tw-spinner {
      @apply h-5 w-5 border-t-transparent border-solid animate-spin rounded-full border-lime-600 border-2
    }
  
    .tw-caption {
      @apply text-xs leading-6 text-gray-500 mt-0
    };
  
    .tw-body-sm {
      @apply flex flex-row justify-start items-center text-xs font-light leading-6 text-gray-600
    };

    .tw-body-1 {
      @apply text-sm leading-6 text-gray-900
    };
  
    .tw-body-2 {
      @apply text-sm font-bold leading-6 text-gray-900
    };
    .tw-body-title {
      @apply text-base font-semibold leading-7 text-gray-900
    };
    .tw-checkbox {
      @apply h-4 w-4 rounded border-gray-300 text-accent focus:ring-accent-600
    };    
  }